<template>
  <section class="dashboard">
    <h1>
      Admin
    </h1>
    <p>View account: <span v-if="$route.params && $route.params.accountId">{{ $route.params.accountId }}</span></p>
    <p>View adz from campaign: <span v-if="$route.params && $route.params.clientId">{{ $route.params.clientId }}</span></p>

    <div class="grid">
      <article class="account-item" v-for="(item, i) in campaignsList" :key="i">
        <h2>{{ item.name }}</h2>
        <p v-for="(adz, j) in item.adzList" :key="j">
          <br>
          <b><strong>{{ adz.id }}</strong></b>
          <span v-if="adz.type === 'multiple'">
            <span v-for="(img, k) in adz.contentSlider" :key="k">
              <!-- <strong>IMG:</strong> {{ img.image }} -->
              <div v-if="img.imageRef"><strong>IMGREF:</strong></div> {{ img.imageRef }}
              <div v-if="img.videoRef"><strong>VIDEOREF:</strong></div> {{ img.videoRef }}
            </span>
          </span>
          <span v-else-if="adz.type === 'single'">
            <!-- <strong>IMG:</strong> {{ adz.content.image }} -->
            <div v-if="adz.content.imageRef"><strong>IMGREF:</strong></div> {{ adz.content.imageRef }}
            <div v-if="adz.content.videoRef"><strong>VIDEOREF:</strong></div> {{ adz.content.videoRef }}
          </span>
        </p>
      </article>
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'

import AccountClientCampaignsDB from '@/firebase/account-client-campaigns-db'
import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import AccountClientCampaignGoogleAdzDB from '@/firebase/account-client-campaign-googleAdz-db'

export default {
  name: 'AdminViewAdz',

  head: function () {
    return {
      title: {
        inner: 'Admin View Adz',
      },
      meta: [
        {
          name: 'description',
          content: `Admin adz`,
          id: 'desc',
        },
      ],
    }
  },

  data() {
    return {
      isLoading: true,
      campaignsList: [
        {
          adzList: [],
        },
      ],
    }
  },

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },

  watch: {
    user: {
      async handler(user) {
        if (!isNil(user)) {
          if (user.appRole !== 'admin') {
            this.$router.push('/')
          }

          const { accountId } = this.$route.params
          const { clientId } = this.$route.params

          const accountClientCampaign = new AccountClientCampaignsDB(accountId, clientId)
          this.campaignsList = await accountClientCampaign.readAll()

          await this.fetchAllAdz()

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },

  methods: {
    async fetchAllAdz() {
      const { accountId } = this.$route.params
      const { clientId } = this.$route.params
      let progress = 0
      const total = this.campaignsList.length

      this.campaignsList.map(async (cObj) => {
        const accountClientCampaignAdz = new AccountClientCampaignAdzDB(accountId, clientId, cObj.id)
        const adz = await accountClientCampaignAdz.readAll()

        const accountClientCampaignGoogleAdz = new AccountClientCampaignGoogleAdzDB(accountId, clientId, cObj.id)
        const gAdz = await accountClientCampaignGoogleAdz.readAll()

        this.campaignsList.find(i => i.id === cObj.id).adzList = [...adz, ...gAdz]

        progress = progress + 1

        if (progress === total) {
          this.campaignsList.sort()
        }
      })
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dashboard {
  padding: 25px;
}

.grid {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 45px 0;

  .account-item {
    width: calc(98% / 3);
    padding: 20px;
    background: rgba(#000, 0.05123);
    border: 2px solid rgba(#000, 0.05123);
    border-radius: 20px;
    margin-bottom: 1%;
    text-align: center;

    color: #323232;

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 60px;
      border-radius: 50%;
    }

    h2 {
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
      line-height: 2;
    }
  }
}
</style>
